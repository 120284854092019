* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  font: inherit;
  font-family: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html, body {
  height: 100% !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html, body {
    overflow-x: hidden;
  }
}
@supports (-ms-ime-align: auto) {
  html, body {
    overflow-x: hidden;
  }
}
@media (max-width: 680px) {
  .drawer-container {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .button-container {
    width: 100vw;
    bottom: 0;
  }
}
@media (min-width: 681px) {
  .drawer-container {
    width: 70vw;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .button-container {
    width: 70vw;
    bottom: 0;
  }
}
@media (min-width: 1024px) {
  .drawer-container {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .button-container {
    width: 50vw;
    bottom: 0;
  }
}
@media (max-width: 1279px) {
  .flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .flex-center > span {
    margin-right: auto;
    margin-left: 20%;
  }

  .flex-center > div {
    width: 100%;
    max-width: 70%;
    box-shadow: none;
  }

  .flex-center label {
    display: block;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    max-width: 70%;
  }
}
.container-answers > div::-webkit-scrollbar {
  height: 4px;
}
.container-answers > div::-webkit-scrollbar-track {
  background-color: #96b7d1;
  border-radius: 8px;
}
.container-answers > div::-webkit-scrollbar-thumb {
  background-color: #167ac6;
  border-radius: 8px;
}

.logo-brand-login {
  display: block;
  height: 100px;
  width: 220px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-date-picker {
  min-width: 260px !important;
}

.grab {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.grab:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.title-drawer-container {
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: #fff;
}

.drawer-content-container {
  width: 100%;
  overflow-y: scroll;
}

.certificate {
  background-image: url("../img/sfondo_attestato.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  width: 800px;
  margin: 0 auto;
  position: relative;
}
.certificate .certificate_container {
  padding: 100px 150px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.certificate .certificate_container .certificate_title {
  font-size: 26px;
  text-align: center;
  width: 100%;
  color: #167ac6;
}
.certificate .certificate_container .certificate_title_course {
  padding-top: 8px;
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: #167ac6;
  font-weight: bold;
}
.certificate .certificate_container .certificate_container_label {
  display: flex;
  padding: 18px 0px;
}
.certificate .certificate_container .certificate_label {
  color: #167ac6;
  padding-right: 10px;
}
.certificate .certificate_container .certificate_label_sub {
  width: 100%;
  color: #167ac6;
}

@media (max-width: 1520px) {
  .hide-column {
    display: none !important;
  }
}
@media (max-width: 1320px) {
  .hide-column-md {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .hide-column-xs {
    display: none !important;
  }
}
@media (max-width: 640px) {
  .hide-column-xxs {
    display: none !important;
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner_load {
  height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner_load .spinner_animate {
  animation: spinner 0.7s linear infinite;
  height: 100px;
  width: 100px;
  color: #ccc;
}

.container {
  position: relative;
  min-height: calc(100vh - 100px);
  background: #f2f3f4;
  clear: both;
}

.container-admin {
  min-height: 100vh;
}

.content-admin {
  transition: 0.2s;
  padding: 120px 50px 50px 50px;
  background-color: #f2f3f4;
  height: 100%;
  min-height: 100vh;
}

@media screen and (max-width: 1024px) {
  .content-admin {
    transition: 0.2s;
    padding: 100px 20px 20px 20px;
    background-color: #f2f3f4;
    height: 100%;
    min-height: 100vh;
  }
}
.modal-paper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-paper .modal-content {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}

.Mui-selected {
  color: #4c23f1 !important;
  font-weight: bold !important;
}

.MuiSelect-root {
  background-color: #f1f1f1 !important;
  border-radius: 20px !important;
  padding: 12px 20px !important;
}

.MuiInputBase-root fieldset {
  border: none !important;
}

@media (max-width: 640px) {
  .container {
    min-height: calc(100vh - 100px);
  }

  .spinner_load {
    height: 200px !important;
  }
}
a {
  text-decoration: none;
}

.content-menu {
  padding-top: 30px;
  clear: both;
}

@media (min-width: 1200px) {
  .content-menu {
    padding: 30px;
    clear: both;
  }
}
@media (min-width: 959px) {
  .line-spacing {
    height: 40px;
    clear: both;
  }
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato"), url("https://elaty-fit.rancher.a2y.space/api/v1/welcome/igt/fonts/1") format("ttf");
}
@font-face {
  font-family: "Steradian";
  font-style: normal;
  font-weight: 600;
  src: local("Steradian"), url("../fonts/steradian/Steradian_Medium.otf") format("otf");
}
@font-face {
  font-family: "Steradian";
  font-style: normal;
  font-weight: 700;
  src: local("Steradian"), url("../fonts/steradian/Steradian_Bold.otf") format("otf");
}
ul.nav {
  position: fixed;
  display: flex;
  width: 100%;
  height: 70px;
  box-sizing: content-box;
  border-bottom: 2px solid #f8f8f8;
  align-items: center;
  z-index: 200;
  background: #fff;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
}
ul.nav li.link {
  list-style-type: none;
}
ul.nav li.link a {
  font-size: 13px;
  color: #000;
  font-weight: 600;
  padding: 27px 25px;
  text-decoration: none;
}
ul.nav li.link a:hover {
  color: #4c23f1;
}
ul.nav li.link-drop {
  width: 180px;
  text-align: left;
  list-style-type: none;
  height: 70px;
  cursor: pointer;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  margin-right: 100px;
}
ul.nav li.link-drop span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
ul.nav li.link-drop ul {
  display: none;
  list-style-type: none;
  z-index: 200;
  position: absolute;
  top: 70px;
  right: 100;
  background: #fff;
  min-width: 180px;
}
ul.nav li.link-drop ul a {
  text-decoration: none;
}
ul.nav li.link-drop ul a li {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  border-bottom: 1px solid #e5e2e2;
  border-left: 1px solid #e5e2e2;
  border-right: 1px solid #e5e2e2;
  text-align: center;
}
ul.nav li.link-drop:hover ul, ul.nav li.link-drop:hover {
  display: block;
}
ul.nav li.logo {
  width: 300px;
  height: 50px;
  margin-left: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: auto;
  list-style-type: none;
}
ul.nav li.logo a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}
ul.nav li.logo a:hover {
  background-color: transparent;
}
ul.nav li.logo-brand {
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  list-style-type: none;
}
ul.nav li.logo-brand a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}
ul.nav li.logo-brand a:hover {
  background-color: transparent;
}
ul.nav li.toggle {
  cursor: pointer;
  display: none;
  position: absolute;
}
ul.nav li.toggle span {
  display: block;
  width: 30px;
  border-top: 3px solid #999;
  margin-bottom: 3px;
}

div.clear {
  height: 70px;
}

div.logo {
  width: 300px;
  height: 50px;
  margin-left: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: auto;
  list-style-type: none;
}

@media (max-width: 768px) {
  div.clear {
    height: 50px;
  }

  ul.nav {
    position: fixed;
    z-index: 200;
    height: 50px;
    transition: 0.1s linear;
    overflow: hidden;
    background: #fff;
  }
  ul.nav li.link {
    width: 100%;
    justify-content: center;
    height: 70px;
  }
  ul.nav li.link a {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    background: #fff;
  }
  ul.nav li.logo {
    width: 300px;
    padding-left: 10px;
    margin: 0 auto;
    height: 40px;
  }
  ul.nav li h3 {
    font-size: 12px;
    width: 100%;
    padding-left: 10px;
    display: none;
  }
  ul.nav li.toggle {
    display: block;
    margin-left: 40vw;
    top: 20px;
  }
  ul.nav li.link-drop {
    display: flex;
    position: absolute;
    width: 100%;
    line-height: 0;
    margin-right: 0;
    color: transparent;
    height: 50px;
  }
  ul.nav li.link-drop span {
    display: none;
  }
  ul.nav li.link-drop ul {
    display: flex;
    top: 50px;
    width: 100%;
    background: #fff;
    justify-content: center;
    flex-direction: column;
    transition: 3s linear;
  }
  ul.nav li.link-drop ul a {
    text-decoration: none;
  }
  ul.nav li.link-drop ul a li {
    height: 70px;
    line-height: 70px;
    background: #fff;
    border-bottom: none;
  }

  .toggle-link {
    height: 150px !important;
  }

  div.logo {
    display: none;
  }
}
li.logo-brand-backoffice {
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  list-style-type: none;
}
li.logo-brand-backoffice a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}
li.logo-brand-backoffice a:hover {
  background-color: transparent;
}

.image-container {
  position: relative;
}
.image-container h1 {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  text-align: center;
}
.image-container p.subtitle {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #fff;
  width: 100%;
  text-align: center;
}
.image-container p.text {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #888;
  width: 65%;
}
.image-container p {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #fff;
}
.image-container span {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.image-container span:hover {
  color: #4c23f1;
}

@media (max-width: 768px) {
  .image-container h1 {
    top: 45%;
  }
  .image-container p.subtitle {
    top: 70%;
  }
  .image-container p {
    top: 20%;
  }
  .image-container p.text {
    width: 90%;
  }
  .image-container span {
    top: 15%;
    left: 20%;
  }

  .span_back {
    top: 50% !important;
  }
}
.login-form {
  width: 450px;
  margin: 0px auto;
  top: 15vh;
  background: #fff;
  position: relative;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
}
.login-form h1 {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 28px;
  top: 15%;
}
.login-form h2 {
  position: absolute;
  font-size: 25px;
  width: 100%;
  top: 15%;
  text-align: center;
}
.login-form form {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 40px;
}
.login-form form input {
  outline: none;
  margin: 30px auto;
  display: block;
  padding: 15px;
  width: 100%;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
  background-color: #f2f3f4;
}
.login-form form input:focus {
  border-color: #4c23f1;
}
.login-form form button {
  font-family: Myriad Pro, sans-serif;
  cursor: pointer;
  outline: none;
  margin: 45px auto;
  border: 0px;
  display: block;
  padding: 15px 0;
  color: #fff;
  background: #4c23f1;
  width: 100%;
  border-radius: 30px;
}
.login-form form span {
  display: block;
  margin-top: 20px;
  font-size: 13px;
  color: #888;
  text-align: center;
}
.login-form form span a {
  text-decoration: none;
}
.login-form form span a:visited {
  color: #888;
}
.login-form form span a:hover {
  color: #4c23f1;
}

@media (max-width: 640px) {
  .login-form {
    width: 90%;
    top: 40px;
  }
  .login-form form {
    top: 15%;
    padding: 40px 20px 20px;
  }
}
.formUser {
  position: relative;
  margin: 80px auto 0;
  max-width: 1000px;
  height: 230px;
}
.formUser div:nth-child(1) {
  position: absolute;
  width: 23%;
  height: 130px;
  background: #fff;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
}
.formUser div:nth-child(1) div {
  position: relative;
  width: 5px;
  top: 32.5px;
  height: 65px;
  border: none;
  left: 0;
}
.formUser div:nth-child(1) div span {
  position: absolute;
  width: 100%;
  height: 32.5px;
  background: #fff;
}
.formUser div:nth-child(1) div span:nth-child(2) {
  top: 32.5px;
}
.formUser div:nth-child(1) div span.toggle-color {
  background: #4c23f1 !important;
  border-radius: 30px;
}
.formUser div:nth-child(1) ul {
  position: relative;
  padding: 0;
  list-style-type: none;
  left: 20px;
  width: 200px;
  top: -20px;
}
.formUser div:nth-child(1) ul li {
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  color: #888;
}
.formUser div:nth-child(1) ul li:hover {
  color: #4c23f1;
}
.formUser div:nth-child(1) ul li:nth-child(1) {
  margin-bottom: 10px;
}
.formUser div:nth-child(1) ul li:nth-child(2) {
  margin-top: 10px;
}
.formUser div:nth-child(2) {
  position: absolute;
  width: 75%;
  height: 220px;
  left: 25%;
}

@media (max-width: 840px) {
  .formUser {
    margin: 40px auto 0;
  }
  .formUser div:nth-child(1) {
    width: 90%;
    left: 5%;
  }
  .formUser div:nth-child(2) {
    width: 90%;
    left: 5%;
    margin-top: 140px;
    height: 300px;
  }
}
main.profile {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
}
main.profile form {
  position: absolute;
  width: 100%;
  height: 100%;
}
main.profile form section {
  float: left;
  width: 50%;
  padding: 25px;
}
main.profile form section label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 7px;
}
main.profile form section input {
  display: block;
  width: 100%;
  outline: none;
  padding: 15px;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
  background-color: #f2f3f4;
}
main.profile form section input:focus {
  border-color: #4c23f1;
}
main.profile form span.line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px solid #e2e7e8;
}
main.profile form button {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  outline: none;
  border: 0px;
  padding: 15px 0;
  color: #fff;
  background: #4c23f1;
  width: 120px;
  font-size: 11px;
  font-weight: 400;
  border-radius: 30px;
}
main.profile form button:hover {
  background-color: #451aee;
}

@media (max-width: 840px) {
  main.profile form section {
    width: 100%;
    padding: 20px 25px 10px;
  }
  main.profile form span.line {
    top: 70%;
  }
  main.profile form button {
    top: 85%;
  }
}
main.account {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #e2e7e8;
  border-radius: 30px;
}
main.account form {
  position: absolute;
  width: 100%;
  height: 100%;
}
main.account form section {
  width: 100%;
  padding: 25px;
}
main.account form section label {
  display: block;
  width: 65%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  padding-left: 20px;
}
main.account form section input {
  display: block;
  width: 65%;
  outline: none;
  padding: 15px;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #e2e7e8;
  float: left;
  margin: 5px 0 25px;
  border-radius: 30px;
  background-color: #f2f3f4;
}
main.account form section input:focus {
  border-color: #4c23f1;
}
main.account form section span {
  display: block;
  width: 120px;
  float: left;
  line-height: 55px;
  margin-left: 20px;
  cursor: pointer;
  color: #888;
  font-size: 13px;
}
main.account form section span:hover {
  color: #4c23f1;
}

@media (max-width: 840px) {
  main.account form section input {
    width: 100%;
  }
  main.account form section label {
    width: 100%;
  }
  main.account form section span {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
form.modify {
  display: block;
}
form.modify h2 {
  display: block;
  text-align: center;
  color: #888;
  font-weight: 400;
  font-size: 20px;
  padding: 20px 0;
}
form.modify input {
  display: block;
  width: 100%;
  outline: none;
  padding: 15px;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #e2e7e8;
  margin: 5px 0 25px;
  font-weight: 400;
  color: #888;
  background-color: #f2f3f4;
  border-radius: 30px;
}
form.modify input:focus {
  border-color: #4c23f1;
}
form.modify button {
  cursor: pointer;
  outline: none;
  margin: 10px auto;
  border: 0px;
  display: block;
  padding: 15px 0;
  color: #fff;
  background: #4c23f1;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  border-radius: 30px;
}
form.modify button:hover {
  background-color: #451aee;
}

h1.home-title {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-align: center;
}

p.home-paragraph {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #fff;
  width: 100%;
  text-align: center;
}

button.home-btn {
  position: absolute;
  background-color: #4c23f1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
button.home-btn a {
  display: block;
  color: #fff;
  text-decoration: none;
  color: #fff;
  background-color: #4c23f1;
  font-size: 11px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  padding: 10px 40px;
}
button.home-btn a:hover {
  background-color: #451aee;
}

footer {
  height: 100px;
  border-top: 1px solid #f3f3f3;
  background-color: #fff;
  position: relative;
  bottom: 0;
  clear: both;
}
footer p {
  position: absolute;
  font-size: 13px;
  color: #167ac6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 15px;
}
footer p:active {
  color: #167ac6;
}

.footer-social {
  margin-left: auto !important;
  display: flex;
  max-width: 300px;
  justify-content: flex-start;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .footer-social {
    margin-right: 20px !important;
    justify-content: flex-end;
  }
}
.course-btn-container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.course-btn {
  display: flex;
  padding: 13px 0;
  color: #fff;
  background: #167ac6;
  width: 140px;
  font-size: 11px;
  font-weight: 400;
  border: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.course-btn:active {
  color: #fff !important;
}
.course-btn:hover {
  background-color: #1474be;
}
.course-btn span {
  display: block;
  width: 60%;
}
.course-btn span:active {
  color: #fff;
}

@media (min-width: 960px) {
  .scrollable-desktop-column {
    overflow-y: scroll;
  }
}
.scrollable-desktop-column {
  overflow-y: auto;
}

.collapse_modulo .MuiPaper-elevation1 {
  box-shadow: none;
}
.collapse_modulo .MuiAccordionSummary-root {
  min-height: 38px !important;
  max-height: 38px !important;
}
.collapse_modulo.Mui-expanded {
  margin: 0 !important;
}

.box {
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: auto;
  margin: 20px 0;
  overflow: auto;
}
.box .box-title {
  color: #167ac6;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.box p {
  margin: 20px;
  color: #000;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}
.box a {
  text-decoration: none;
}
.box button {
  cursor: pointer;
  outline: none;
  margin: 0 auto;
  border: 0px;
  display: block;
  padding: 17px 0;
  color: #000;
  background: #4c23f1;
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  text-transform: uppercase;
  border-radius: 30px;
}
.box button:hover {
  background: #451aee;
}
.box ul {
  width: 50%;
  float: left;
  list-style-type: none;
  padding: 15px 30px;
  color: #000;
}
.box ul li {
  padding: 5px 0;
}
.box ul:nth-child(3) {
  text-align: right;
}
.box ul.docent {
  text-align: left;
  width: 100%;
  margin: 0 auto;
}
.box ul.docent h6 {
  color: #000;
}
.box ul.docent p {
  margin: 0;
  width: 100%;
  text-align: left;
  display: block;
}

video.seek-video::-webkit-media-controls-timeline {
  pointer-events: none;
}

.box_btn {
  background-color: #fff;
  width: 90%;
  height: auto;
  margin: 20px auto;
  border: 1px solid #e2e7e8;
  overflow: auto;
  border-radius: 30px;
}
.box_btn button {
  cursor: pointer;
  outline: none;
  margin: 0 auto;
  border: 0px;
  display: block;
  padding: 17px 0;
  color: #fff;
  background: #4c23f1;
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  text-transform: uppercase;
  border-radius: 30px;
}
.box_btn button:hover {
  background: #451aee;
}

.container_course {
  min-height: 350px;
  margin-left: 2.4%;
  margin-bottom: 30px;
  float: left;
  width: 22%;
  min-width: 250px;
}

.card-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  overflow: auto;
}

.title-typology {
  color: #167ac6;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin-left: 2.4%;
}

.cardsCourse {
  border-radius: none !important;
  box-shadow: "0 4px 20px rgba(0, 0, 0, 0.25)";
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-height: 350px;
}

.title-category {
  padding: 15px 0px 15px 30px;
  font-weight: 600;
  color: #167ac6;
  font-size: 20px;
  text-transform: uppercase;
}

@media (max-width: 330px) {
  .cardsCourse {
    border-radius: none !important;
    box-shadow: "0 4px 20px rgba(0, 0, 0, 0.25)";
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
.module-index {
  display: block;
  width: 100%;
  color: #888;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 10px;
}

.module-name {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  padding-left: 10px;
}

.module-paragraph {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
}

.display div {
  display: block !important;
}

.module-icon {
  height: 30px;
  width: 500px;
}
.module-icon ul {
  margin-top: 20px;
  list-style-type: none;
  display: block;
  width: 100%;
  height: 100%;
}
.module-icon ul li {
  display: block;
  width: 33%;
  float: left;
  height: 30px;
  padding: 0 10px;
}
.module-icon ul li span {
  display: block;
  float: right;
  width: 100px;
  line-height: 30px;
  text-align: left;
  color: #888;
  font-size: 14px;
}

.lesson div {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.lesson div p {
  display: block;
  padding: 20px 0;
}
.lesson button {
  cursor: pointer;
  outline: none;
  border: 0px;
  display: block;
  padding: 17px 20px;
  color: #fff;
  margin: 0 auto;
  background: #4c23f1;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 30px;
}
.lesson button:hover {
  background: #451aee;
}

.containerlesson {
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #e2e7e8;
  background-color: #fff;
  border-radius: 30px;
}

.containerlesson:last-child {
  margin-bottom: 40px;
}

.iconplay {
  display: flex;
  height: 100%;
  align-items: center;
}

@media (max-width: 1024px) {
  .Line {
    height: 0px !important;
  }

  .container_course {
    margin-left: 2.5%;
    width: 30%;
  }
}
@media (max-width: 768px) {
  .container_course {
    margin-left: 5%;
    width: 42.5%;
  }

  .module-icon {
    height: 30px;
    width: 100%;
  }
  .module-icon ul {
    margin-top: 20px;
    list-style-type: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  .module-icon ul li {
    display: block;
    width: 100%;
    float: left;
    height: 30px;
    padding: 0 10px;
  }
  .module-icon ul li span {
    display: block;
    float: left;
    width: 40%;
    line-height: 30px;
    text-align: left;
    color: #888;
    font-size: 14px;
    margin-left: 25%;
  }

  .module-index {
    padding-left: 0;
  }

  .module-name {
    padding-left: 0;
  }

  .module-paragraph {
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .container_course {
    margin-left: 5%;
    width: 90%;
  }

  .iconplay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
  }

  .module-index {
    font-size: 16px;
  }

  .module-name {
    font-size: 20px;
  }
}
.Line {
  height: 50px;
}

.class-wrapper {
  height: 360px;
  padding: 0px 10px;
  margin: 0 auto;
}

.course-wrapper {
  height: 360px;
}

.arrow-slider {
  width: 50px;
}

.container-message {
  background: #ededed;
  width: 70%;
  min-height: 150px;
  margin: 20px auto;
  font-size: 14px;
}
.container-message > p {
  padding-top: 25px;
  padding-left: 25px;
  text-transform: uppercase;
  font-weight: 600;
}
.container-message div {
  margin: 0 25px 25px;
  padding-left: 25px;
  border-bottom: 2px solid #e2e7e8;
}
.container-message div h2 {
  padding-top: 5px;
  font-size: 20px;
  font-weight: 600;
}
.container-message div h3 {
  padding-top: 10px;
  color: #888;
}
.container-message div p {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  display: block;
}

.text-area-fixed {
  position: fixed;
  width: 100%;
  height: auto;
  min-height: 170px;
  background: #ededed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-area-fixed div {
  height: 100%;
}
.text-area-fixed div:nth-child(1) {
  margin: 10px;
}
.text-area-fixed div:nth-child(1) textarea {
  min-width: 400px;
  padding: 10px;
}
.text-area-fixed div:nth-child(2) {
  margin: 10px;
}
.text-area-fixed div:nth-child(2) button {
  cursor: pointer;
  outline: none;
  border: 0px;
  padding: 15px 0;
  color: #fff;
  background: #4c23f1;
  width: 120px;
  font-size: 11px;
  font-weight: 400;
}
.text-area-fixed div:nth-child(2) button:hover {
  background-color: #451aee;
}

@media (max-width: 768px) {
  .text-area-fixed {
    flex-direction: column;
  }
  .text-area-fixed div:nth-child(1) textarea {
    width: 100%;
    min-width: auto;
  }
}
.survey-error {
  width: 500px;
  margin: 70px auto;
  min-height: 250px;
  height: auto;
  background: #fff;
  border: 1px solid #e2e7e8;
}
.survey-error h2 {
  display: block;
  text-align: center;
  padding: 30px 0 10px;
  font-size: 24px;
}
.survey-error p {
  text-align: center;
  padding: 30px 35px;
}
.survey-error button {
  text-transform: uppercase;
  display: block;
  margin: 20px auto;
  cursor: pointer;
  outline: none;
  border: 0px;
  padding: 15px 0;
  color: #fff;
  background: #4c23f1;
  width: 150px;
  font-size: 11px;
  font-weight: 400;
}
.survey-error button:hover {
  background-color: #451aee;
}

@media (max-width: 640px) {
  .survey-error {
    width: 90%;
  }
  .survey-error p {
    text-align: center;
    padding: 0px 15px;
  }
}
.container_video {
  position: relative;
  background: #f2f3f4;
  width: 100%;
  height: 100%;
}
.container_video .btn-overlay {
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.container_video .btn-overlay .start-overlay {
  padding: 10px 30px;
  margin: 0 10px;
  background-color: #3fbe54 !important;
}
.container_video .btn-overlay .resume-overlay {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #3fbe54 !important;
}
.container_video .btn-overlay .restart-overlay {
  padding: 10px 20px;
  margin: 0 10px;
}

.video {
  background: #fff;
  position: relative;
}
.video div {
  clear: both;
  margin: 0 auto;
}
.video div div {
  position: relative;
  z-index: 20;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.video div div iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.button_back {
  color: #fff !important;
}

button.show-lesson {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ccc;
  display: block;
  margin-top: 50px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.section_container {
  display: flex !important;
  align-items: center;
  min-height: 57px !important;
}

.video_related {
  width: 100%;
  background: #f2f3f4;
}
.video_related div {
  padding: 0 !important;
}

.related_content {
  width: 100%;
  margin-bottom: 0px;
  background: #f2f3f4;
}
.related_content ul {
  display: block;
  width: 100%;
  background-color: #f2f3f4;
}
.related_content ul::-webkit-scrollbar {
  width: 0px;
  background-color: #fff;
  border-radius: 10px;
}
.related_content ul::-webkit-scrollbar-thumb {
  background-color: #167ac6;
  border-radius: 10px;
}
.related_content ul li.playing_lesson section .related_title h4 {
  color: #fff;
}
.related_content ul li.playing_lesson section .related_title h4:active {
  color: #fff !important;
}
.related_content ul li.playing_lesson section .related_title h5 {
  color: #fff;
}
.related_content ul li.playing_lesson section .related_title h5:active {
  color: #fff !important;
}
.related_content ul li.playing_lesson section .related_title p {
  color: #fff;
}
.related_content ul li.playing_lesson section .related_title p:active {
  color: #fff !important;
}
.related_content ul li.playing_lesson section .related_icon .icon_play_related {
  color: #fff;
}
.related_content ul li.playing_lesson section .related_icon .icon_play_related:active {
  color: #fff !important;
}
.related_content ul li.playing_lesson section .related_icon span {
  color: #fff;
}
.related_content ul li {
  display: block;
  width: 100%;
  overflow: auto;
}
.related_content ul li section {
  display: block;
  width: 100%;
  overflow: auto;
  min-height: 60px;
  max-height: 60px;
  padding: 0px 25px 0px 8px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.related_content ul li .related_cup {
  position: relative;
  display: flex;
  width: 20%;
  float: left;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 56px;
}
.related_content ul li .related_title {
  display: block;
  width: 70%;
  float: left;
}
.related_content ul li .related_title h4 {
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
  color: #fff;
  font-weight: bolder;
  padding-left: 5px;
}
.related_content ul li .related_title h4:active {
  color: #fff !important;
}
.related_content ul li .related_title h5 {
  font-size: 13px;
  display: block;
  color: #fff;
  opacity: 0.7;
  font-weight: bolder;
  padding-left: 5px;
}
.related_content ul li .related_title h5:active {
  color: #fff !important;
}
.related_content ul li .related_title p {
  display: block;
  color: #fff;
  padding-left: 5px;
}
.related_content ul li .related_title p:active {
  color: #fff !important;
}
.related_content ul li .related_icon {
  position: relative;
  display: flex;
  width: 12%;
  float: left;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 56px;
}
.related_content ul li .related_icon .icon_play_related {
  height: 25px;
  width: 25px;
  color: #fff;
}
.related_content ul li .related_icon .icon_play_related:active {
  color: #fff !important;
}
.related_content ul li .related_icon .icon_play_absolute {
  position: absolute;
  top: 0;
  left: 57%;
}
.related_content ul li .related_icon span {
  color: #fff;
}
.related_content ul li .related_icon span:active {
  color: #fff !important;
}
.related_content ul li:hover .related_icon .icon_play_related {
  color: #fff;
}
.related_content ul li:hover .related_icon span {
  color: #fff;
}
.related_content ul li:hover .related_title h4 {
  color: #fff;
}
.related_content ul li:hover .related_title h5 {
  color: #fff;
}
.related_content ul li:hover .related_title p {
  color: #fff;
}

.collapse_modulo {
  background-color: #fff !important;
  /*
      &:not(:last-child){
          margin:0!important;
      }
  */
}
.collapse_modulo .collapse_text {
  background-color: #fff;
  padding: 0 20px;
  color: #167ac6;
  font-weight: bold;
  border-bottom: 2px solid #167ac6;
}
.collapse_modulo .collapse_text:active {
  color: #167ac6 !important;
}
.collapse_modulo .collapse_text:last-child {
  border-top: 0px;
}
.collapse_modulo .collapse_text h2 {
  text-transform: uppercase;
  line-height: 22px;
  padding: 8px 0 8px 24px;
  display: block;
  width: 100%;
  text-align: center;
}
.collapse_modulo .collapse_text h2:active {
  color: #167ac6;
}
.collapse_modulo .collapse_content {
  align-items: center;
  flex-direction: column;
  background: #167ac6;
}
.collapse_modulo .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

@media (max-width: 1400px) {
  .video_related {
    width: 100%;
  }

  .related_content {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .collapse_modulo {
    margin-left: 0 !important;
  }

  .next_lesson {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    font-weight: bold;
  }

  .video_related {
    width: 100%;
    padding-left: 0;
  }
  .video_related div {
    padding: 0 !important;
  }

  .related_content {
    width: 100%;
    margin: 0 auto;
    clear: both;
  }
  .related_content ul {
    display: block;
    width: 100%;
    height: auto;
    overflow-y: auto;
    padding: 0;
  }
  .related_content ul li {
    display: block;
    width: 95%;
    margin: 0 auto;
  }
  .related_content ul li .related_icon {
    flex-direction: row;
  }

  .collapse_text {
    margin: 0 !important;
  }
}
@media (max-width: 500px) {
  .conditional_height {
    height: 200px;
    clear: both;
  }

  .container_video {
    overflow: auto;
  }

  .video_related {
    width: 100%;
  }

  .related_content {
    width: 100%;
    margin: 0 auto;
    clear: both;
    margin-top: 20px;
  }
  .related_content ul {
    display: block;
    width: 100%;
    height: auto;
  }
  .related_content ul li section {
    padding: 10px 10px;
  }
  .related_content ul li .related_icon {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .button_back {
    width: 100%;
  }

  .conditional_height {
    height: 170px;
  }
}
@media (max-width: 400px) {
  .conditional_height {
    height: 140px;
  }
}
@media (max-width: 350px) {
  .conditional_height {
    height: 130px;
  }
}
.module-box {
  box-shadow: -1px 7px 14px 2px rgba(0, 0, 0, 0.47);
  background-color: #fff;
  padding: 20px;
}
.module-box h3 {
  color: #585858;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
}
.module-box h4 {
  font-size: 24px;
  font-weight: 600;
  color: #585858;
  padding: 10px 0;
}
.module-box p {
  padding-top: 5px;
  line-height: 22px;
  font-size: 16px;
  color: #585858;
}

.videoStart {
  box-shadow: -1px 7px 14px 2px rgba(0, 0, 0, 0.47);
  background-color: #fff;
  padding: 20px;
}
.videoStart h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #585858;
}
.videoStart h4 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #585858;
  padding: 10px 0 20px;
}
.videoStart button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  height: 38px;
  padding: 0 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.videoStart .btn_green {
  background-color: #3fbe54;
}
.videoStart .btn_green:hover {
  background-color: #3aac4d;
}
.videoStart .btn_green:active {
  color: #fff !important;
}
.videoStart .btn_blue:hover {
  -webkit-filter: grayscale(20%);
  filter: grayscale(20%);
}
.videoStart .btn_blue:active {
  color: #fff !important;
}

@media (max-width: 400px) {
  .btn_green {
    height: 50px !important;
    background-color: #3fbe54;
  }
  .btn_green:hover {
    height: 50px !important;
    background-color: #3aac4d;
  }

  .btn_blue {
    height: 50px !important;
    background-color: #167ac6;
  }
  .btn_blue:hover {
    height: 50px !important;
    background-color: #166eb1;
  }
}
.teaching-material {
  background-color: #fff;
  border: 1px solid #f2f3f4;
  border-radius: 10px;
}
.teaching-material div.teaching-title {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #f2f3f4;
}
.teaching-material div.teaching-title h3 {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.teaching-material div.teaching-box {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f4;
}
.teaching-material div.teaching-box h4 {
  padding-left: 10px;
}
.teaching-material div.teacher-info {
  padding: 15px;
}
.teaching-material ul {
  display: block;
  list-style-type: none;
  padding: 20px;
}
.teaching-material ul li {
  display: block;
  height: 30px;
}
.teaching-material ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  text-decoration: none;
  padding: 5px 0;
}
.teaching-material ul li a .svg-download {
  color: #167ac6;
  margin-right: 20px;
}

.backCourse {
  padding-left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.backCourse h2 {
  cursor: pointer;
}

.selected-lesson {
  background-color: #0969b3;
}

.activeD:active {
  color: #3aac4d !important;
}

.view-search {
  display: flex;
  align-items: center;
}

.view-group-cards {
  padding-top: 50px;
  max-width: 100%;
}

.first-group-card {
  display: flex;
  flex-direction: row;
  padding: 40px;
  border-radius: 20px !important;
}

.last-two-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cards-half {
  padding: 40px;
  border-radius: 20px !important;
  margin-top: 40px;
  width: 48%;
  max-width: 48%;
}

.label-attivita {
  font-size: 24px;
  color: #4c23f1;
  font-weight: bold;
}

.month-label {
  font-size: 20px;
  margin: 15px 0 50px 0;
  color: #888;
}

.student-state-label {
  font-size: 24px;
  font-weight: bold;
  color: #4c23f1;
}

.box-statistics {
  padding-left: 60px;
}

.title-statistics {
  font-size: 24px;
  font-weight: bold;
}

.view-value-stat {
  padding: 20px 0;
}

.value-statistics {
  font-size: 20px;
  color: #4c23f1;
  font-weight: bold;
}

.value-statistics:last-child {
  margin-left: 50px;
}

.label-statistics {
  color: #888;
}

.result-title-questions {
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 17px;
  font-weight: 600;
  max-width: 400px;
  text-align: left;
  padding: 6px 12px;
}

.question-result-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 6px 12px;
}
.question-result-container .correct-question-result {
  font-weight: 500;
  font-size: 16px;
}
.question-result-container .not-correct-question-result {
  font-weight: 500;
  font-size: 16px;
}

.loading-questions-result {
  width: 100%;
  text-align: center;
  color: #8a8888;
  font-size: 16px;
}

.sidebar > div {
  top: 120px !important;
  border-top-right-radius: 25px;
}
.sidebar .fullList {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.sidebar .fullList .listItem:first-child {
  border-top-right-radius: 25px;
}
.sidebar .fullList .listItem:first-child .listIcon {
  border-top-right-radius: 25px;
}
.sidebar .fullList .listItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 0;
  width: 100%;
}
.sidebar .fullList .listItem .list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sidebar .fullList .listItem .list .listIcon {
  color: #fff;
  width: 100%;
  height: 40px;
}
.sidebar .fullList .listItem .list .listText {
  color: #fff;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  font-weight: bold;
  font-size: 16px;
}

.menu-table {
  color: #4c23f1 !important;
  font-size: 14px;
}

.menu-table-del {
  color: red !important;
  font-size: 14px;
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;
}

.title_corsi_admin {
  color: #999;
  font-weight: bold;
  font-size: 20px;
}
.title_corsi_admin span {
  color: #4c23f1;
  text-transform: capitalize;
}

.subscription_course {
  padding-top: 15px;
  color: #4c23f1;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subscription_course .subscription_icon {
  padding-left: 10px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.subscription_course .subscription_icon:active {
  color: #4c23f1;
}
.subscription_course .subscription_icon_back {
  padding-left: 10px;
  background-color: #4c23f1;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.subscription_course .subscription_icon_back:active {
  color: #4c23f1;
}
